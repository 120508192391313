.mainHeader {
  position: absolute;
  z-index: 9999;
  right: 0px;
  left: 0px;
  top: 46px;
  background-color: transparent;
  transition: all 0.4s;
  .navbar-brand {
    position: relative;
    z-index: 9;
    img {
      max-width: 152px;
    }
  }
  .connectWallet {
    width: 183px;
    padding: 17px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      letter-spacing: 0.3px;
    }
    img {
      width: 27px;
      height: auto;
      margin-right: 10px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 9px;
    li {
      padding: 0px 21px 3px 21px;
      a {
        font-size: 20.5px;
        line-height: 43px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          color: #0272f1;
        }
      }
    }
  }
}
// .header-active {
//   background-color: #0b0b0c;
//   top: 0px !important;
//   padding: 10px 0px;
//   transition: all 0.4s;
// }
//  =========================//
// ===============[MEDIA]=====================//
@media (max-width: 1400px) {
  .mainHeader {
    ul {
      li {
        a {
          font-size: 17px;
          line-height: 43px;
          color: #fff;
          font-weight: 600;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}
@media (max-width: 991.98px) {
  .mainHeader:has(.navbar-collapse.show) {
   position: fixed;

  }
  .mainHeader {
    .navbar-collapse.collapsing {
      height: auto !important;
    }
    .navbar-toggler {
      padding: 0px;
      z-index: 999;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .navbar-collapse {
      background: #0b0b0c;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 0;
      transform: translateY(-100%);
      transition: all 0.5s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      ul {
        flex-direction: column;
        padding: 0px;
        margin: 0 !important;
        li {
          a {
            font-size: 23px;
            line-height: 56px;
          }
        }
      }
      .connectWallet {
        margin-top: 50px;
        padding: 19px 40px !important;
        span {
          font-size: 23px !important;
          line-height: 35px;
        }
        img {
          width: 30px !important;
          height:auto !important;
          margin-right: 18px !important;
        }
      }
    }
    .navbar-collapse.show {
      height: 100vh;
      transform: translateY(0%);
    }
  }
  .collapsed {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px !important;
    span {
      width: 34px;
      height: 5px;
      border-radius: 10px;
      background-color: #fff;
      display: block;
      transition: all 0.5s ease;
      transform: rotate(0deg) translateX(0px) !important;
      &:nth-child(2) {
        width: 20px;
        display: block !important;
      }
    }
  }
  .mobileMenu {
    span {
      width: 29px;
      height: 4px;
      border-radius: 10px;
      background-color: #fff;
      display: block;
      transition: all 0.5s ease;
      &:nth-child(2) {
        width: 18px;
        display: none;
        margin: 6px 0px;
      }
    }
    span {
      &:nth-child(1) {
        transform: rotate(46deg) translateX(3px);
      }
      &:nth-child(3) {
        transform: rotate(-46deg) translateX(3px);
      }
      &:nth-child(2) {
        width: 18px;
        display: none;
        margin: 6px 0px;
      }
    }
  }
}
@media (max-width: 768.98px) {
  .mainHeader:has(.navbar-collapse.show) {
    top: 23px !important;
    padding: 0px !important;
  }
}
@media (max-width: 500px) {
  .mainHeader {
    .navbar-collapse {
      .connectWallet {
        padding: 16px 26px !important;
      }
    }
  }
}

@media (max-width: 420px) {
  .mainHeader:has(.navbar-collapse.show) {
    top: 40px !important;
  }
}
@media (max-width: 375px) {
  .mainHeader:has(.navbar-collapse.show) {
    top: 34px !important;
  }
}
