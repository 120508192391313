// -----loder-------
.loader-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}
.mainLoder-section {
  position: relative;
  height: 100vh;
  .loderImg {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .inner-box {
    position: relative;
    z-index: 2;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 110px 0px 100px 0px;
    .loder-logo {
      max-width: 460px;
      margin-bottom: 25px;
    }
    h2 {
      font-size: 32px;
      font-weight: 600;
      .dots {
        animation: bounce 0.5s alternate infinite;
        display: block;
        &:nth-child(2) {
          animation-delay: 0.16s;
        }
        &:nth-child(3) {
          animation-delay: 0.32s;
        }
      }
    }
  }
}
@keyframes bounce {
  from {
    tranform: scaleX(1.25);
  }
  to {
    transform: translateY(-10px) scaleX(1);
  }
}
body {
  overflow: hidden;
}
.overflow {
  overflow: inherit !important;
}
.lodingRemove {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
// ----/loder------
section {
  color: #fff;
  background-color: #151616;
}
.mainSticky-section {
  position: relative;
  min-height: 2000px;
  width: 100%;
  .section-skin-select {
    position: sticky;
    right: 0;
    left: 0;
    top: 0px;
    bottom: 0;
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
.container {
  padding: 0px 145px;
  max-width: 100%;
  width: 100%;
}
.section-hero {
  position: relative;
  background-color: #151616;
  // padding-top: 220px;
  // padding-bottom: 135px;
  // min-height: 1150px;
  display: flex;
  align-items: center;
  .mainTop-content {
    position: absolute;
    padding-top: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0px;
  }
  .row-hero-section {
    .textBlok-left {
      position: relative;
      z-index: 2;
    }
    .sm-dBlock-img {
      display: none;
    }
  }
  .sm-blockLogo {
    display: none;
  }
  .sub-heading {
    font-size: 36.5px;
    line-height: 38px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    font-size: 21px;
    line-height: 33px;
    color: #cacaca;
    font-weight: 300;
    width: 602px;
    margin-top: 10px;
    margin-bottom: 35px;
  }
  .turboHiro {
    .lg-blockLogo {
      max-width: 74%;
    }
  }
  .connectWallet {
    width: 275px;
    padding: 17px 10px;
    &:last-child {
      margin-left: 10px;
      width: 220px;
    }
    span {
      font-size: 22.5px;
    }
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .hiro-rightMobile {
    width: 100%;
  }
}
.section-swap-event {
  background-color: #fff;
  color: #08080a;
  padding: 20px 0px 20px 0px;
  position: relative;
  &::before {
    content: "";
    background-image: url("../../public/Assets/private-swap-bg.png");
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  .mobileRound-1,
  .mobileRound-2,
  .mobileRound-3 {
    display: none;
  }
  .row-private-swap-event {
    .joinName {
      display: block;
      font-size: 38px;
      line-height: 40px;
      font-weight: 700;
    }
    .heading {
      font-size: 60px;
      line-height: 65px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    p {
      font-size: 22px;
      line-height: 33px;
      font-weight: 500;
    }
    .rightPrivate-text {
      padding-left: 70px;
    }
    .btn-black {
      font-size: 22px;
      font-weight: 600;
      list-style: 25px;
      padding: 15px 70px;
      color: #fff;
      background-color: #08080a;
      letter-spacing: 1.5px;
      border-radius: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      transition: all 0.5s;
      &:hover {
        background-color: #fff;
        color: #08080a;
      }
    }
  }
}
.section-skin-select {
  padding: 117px 0px 100px 0px;
  position: relative;
  &::before {
    content: "";
    background-image: url("../../public/Assets/skin-select-bg.png");
    position: absolute;
    top: 100px;
    bottom: 0px;
    left: 65px;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    width: 50%;
    height: auto;
  }
  .row-skin-select {
    .heading {
      font-size: 59px;
      line-height: 66px;
      font-weight: 700;
    }
    .leftPhone-screen {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .hide {
        display: none;
      }
      .chnageImg-mobile {
        width: 82%;
        z-index: 2;
        position: relative;
      }
    }
    .main-textBox {
      list-style: none;
      padding-left: 80px;
      li {
        &:first-child {
          opacity: 1;
        }
        margin-bottom: 25px;
        opacity: 0.1;
        transition: all 0.5s;
        &:last-child {
          margin-bottom: 0px;
        }
        &.active {
          opacity: 1;
        }
        h4 {
          font-size: 40px;
          line-height: 55px;
          color: #ffffff;
          margin-bottom: 0px;
          font-weight: 600;
        }
        span {
          display: block;
          font-size: 20px;
          line-height: 30px;
          color: #cacaca;
          font-weight: 300;
        }
      }
    }
  }
}
.section-road-report {
  .row-road-report {
    .decentralized-block {
      padding-right: 100px;
      .decentralized {
        font-size: 30px;
        line-height: 25px;
        font-weight: 600;
      }
      .heading {
        font-size: 59px;
        line-height: 66px;
        font-weight: 700;
      }
      img {
        width: 100%;
        margin: 15px 0px 8px 0px;
      }
      ul {
        padding: 0px;
        li {
          list-style: none;
          margin-bottom: 22px;
          :last-child {
            margin-bottom: 0px;
          }
          .sub-heading {
            font-size: 38px;
            line-height: 65px;
            font-weight: 600;
            margin-bottom: 0px;
          }
          span {
            font-size: 20.5px;
            line-height: 30px;
            color: #cacaca;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.section-carplay {
  text-align: center;
  position: relative;
  padding-top: 157px;
  .heading {
    font-size: 59px;
    line-height: 77px;
    color: #ffffff;
    font-weight: 700;
    width: 738px;
    margin: auto auto 10px auto;
  }
  .main-sectionmobileCarplay {
    position: relative;
  }
}
.main-section-turboVehicles {
  position: relative;
  padding-top: 150px;
  padding-bottom: 120px;
  // background: linear-gradient(152deg, rgb(21, 22, 22) 0%, rgb(21, 19, 21) 100%);
  // &::before {
  //   content: "";
  //   background-image: url("../../public/Assets/skin-select-bg.png");
  //   position: absolute;
  //   top: 0px;
  //   bottom: 0px;
  //   right: 0px;
  //   background-position: center right;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   width: 51%;
  //   z-index: 0;
  //   height: 100%;
  // }
  .turboVehical-left {
    padding-right: 75px;
  }
  .heading {
    font-size: 59px;
    line-height: 66px;
    font-weight: 700;
  }
  .leftImg {
    img {
      margin: 15px 0px 15px 0px;
      // padding-right: 40px;
    }
    span {
      text-align: justify;
      display: block;
      margin-bottom: 17px;
      width: 733px;
    }
  }
  span {
    font-size: 21px;
    line-height: 30px;
    color: #cacaca;
    font-weight: 300;
    display: block;
  }
  .main-innervehiclesText {
    .subText {
      margin-bottom: 30px;
      &:nth-child(1) {
        padding-right: 22px;
        span {
          width: 285px;
        }
      }
      &:nth-child(3) {
        padding-right: 22px;
        span {
          width: 344px;
        }
      }
      &:nth-child(2) {
        span {
          text-align: justify;
          width: 365px;
        }
      }
      &:nth-child(4) {
        span {
          width: 365px;
        }
      }
    }
    h2 {
      font-size: 38px;
      line-height: 65px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #cacaca;
    }
  }
  .rightImg {
    position: relative;
    z-index: 3;
    padding-left: 0px !important;
    img {
      width: 100%;
    }
  }
}
//  =========================//
// ===============[MEDIA]=====================//
@media (min-height: 300px) and (max-height: 870px) {
  .section-road-report {
    padding-top: 100px;
  }
}
@media (min-height: 300px) and (max-height: 807px) {
  .section-skin-select {
    // &::before {
    //   top: 57px;
    //   left: 79px;
    //   width: 43% !important;
    // }
    // .row-skin-select {
    //   .main-textBox {
    //     padding-left: 0px;
    //   }
    // }
  }
}
@media (min-height: 300px) and (max-height: 700px) {
  .mainLoder-section {
    .inner-box {
      padding: 50px 0px 30px 0px;
      .loder-logo {
        max-width: 50%;
        margin-bottom: 15px;
      }
      h2 {
        font-size: 22px;
      }
    }
  }
}
@media (min-height: 300px) and (max-height: 650px) {
  .mainSticky-section {
    min-height: 1600px;
  }
  .section-skin-select {
    // &::before {
    //   left: 76px;
    // }
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 60%;
        }
      }
      .heading {
        font-size: 40px !important;
        line-height: 44px !important;
      }
      .main-textBox {
        li {
          margin-bottom: 14px;
          h4 {
            font-size: 27px !important;
            line-height: 34px !important;
          }
          span {
            font-size: 15px !important;
            line-height: 21px !important;
          }
        }
      }
    }
  }
  .section-hero {
    margin-bottom: 40px;
  }
  body {
    background-color: #151616;
  }
}
@media (min-height: 620px) and (max-height: 650px) {
  .section-skin-select {
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 81% !important;
        }
      }
    }
  }
}
@media (min-height: 400px) and (max-height: 620px) {
  .section-skin-select {
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 79% !important;
        }
      }
    }
  }
}
@media (max-width: 1800px) {
  .section-swap-event {
    &::before {
      background-position: 37% 37%;
    }
    .row-private-swap-event {
      .mainImg-join {
        width: 104%;
        max-width: 110%;
        margin-left: -12px;
      }
      .rightPrivate-text {
        padding-left: 84px;
      }
      .joinName {
        font-size: 35px;
        line-height: 28px;
        margin-top: 10px;
      }
      .heading {
        font-size: 56px;
        margin-bottom: 9px;
        line-height: 69px;
      }
      p {
        font-size: 19px;
        width: 610px;
        line-height: 30px;
      }
      .btn-black {
        font-size: 20px;
        padding: 15px 61px;
      }
    }
  }
}
@media (max-width: 1750px) {
  .container {
    padding: 0px 138px;
  }
  .main-section-turboVehicles {
    .heading {
      font-size: 54px;
      line-height: 57px;
    }
    // .rightImg {
    //   img {
    //     width: 67%;
    //   }
    // }
    .leftImg {
      span {
        width: 677px;
      }
    }
    span {
      font-size: 19px;
      line-height: 28px;
    }
    .main-innervehiclesText {
      h2 {
        font-size: 34px;
        line-height: 52px;
      }
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 337px;
          }
        }
        &:nth-child(3) {
          span {
            width: 304px;
          }
        }
      }
    }
  }
  .section-hero {
    .mainTop-content {
      padding-top: 75px;
    }
    p {
      font-size: 19.5px;
      line-height: 31px;
      width: 512px;
    }
    .sub-heading {
      font-size: 34px;
      margin-bottom: 16px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 68%;
      }
    }
    .connectWallet {
      width: 256px;
      padding: 15px 10px;
      span {
        font-size: 20.5px;
      }
      img {
        width: 21px;
        margin-right: 9px;
      }
      &:last-child {
        width: 206px;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 79px;
        .decentralized {
          font-size: 27px;
          line-height: 23px;
        }
        .heading {
          font-size: 53px;
          line-height: 53px;
        }
        ul {
          li {
            .sub-heading {
              font-size: 35px;
              line-height: 56px;
            }
            span {
              font-size: 19.5px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 54px;
      line-height: 67px;
    }
  }
}
@media (max-width: 1700px) {
  .container {
    padding: 0px 135px;
  }
  .mainHeader {
    top: 42px;
    .navbar-brand {
      img {
        max-width: 130px;
      }
    }
    ul {
      li {
        a {
          font-size: 18.5px;
        }
      }
    }
    .connectWallet {
      width: 167px;
      padding: 15px 10px;
      span {
        font-size: 19px;
      }
    }
  }
  .section-swap-event {
    &::before {
      background-position: 37% 37%;
    }
    .row-private-swap-event {
      .mainImg-join {
        width: 105%;
        max-width: 110%;
        margin-left: -18px;
      }
      .rightPrivate-text {
        padding-left: 79px;
      }
      .joinName {
        font-size: 33px;
        line-height: 25px;
        margin-top: 15px;
      }
      .heading {
        font-size: 52px;
        margin-bottom: 9px;
        line-height: 65px;
      }
      p {
        font-size: 18px;
        width: 580px;
        line-height: 30px;
      }
      .btn-black {
        font-size: 19px;
        padding: 15px 56px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 50px;
        line-height: 49px;
      }
      .main-textBox {
        padding-left: 72px;
        li {
          h4 {
            font-size: 34px;
            line-height: 52px;
          }
          span {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
@media (max-width: 1650px) {
  .container {
    padding: 0px 130px;
  }
  .mainHeader {
    top: 37px;
    .navbar-brand {
      img {
        max-width: 125px;
      }
    }
    ul {
      padding-left: 21px;
      li {
        padding: 0px 20px 3px 20px;
        a {
          font-size: 17.5px;
        }
      }
    }
    .connectWallet {
      width: 161px;
      padding: 13px 10px;

      span {
        font-size: 18px;
      }
      img {
        width: 23px;
      }
    }
  }
  .main-section-turboVehicles {
    .heading {
      font-size: 50px;
      line-height: 50px;
    }
    // .rightImg {
    //   padding-left: 0px;
    //   img {
    //     width: 65%;
    //   }
    // }
    .leftImg {
      img {
        // padding-right: 19px;
      }
      span {
        width: 632px;
      }
    }
    span {
      font-size: 18px;
      line-height: 27px;
    }
    .main-innervehiclesText {
      h2 {
        font-size: 32px;
        line-height: 50px;
      }
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 316px;
          }
        }
        &:nth-child(3) {
          span {
            width: 287px;
          }
        }
      }
    }
  }
  .section-hero {
    .mainTop-content {
      padding-top: 68px;
    }
    p {
      font-size: 18.5px;
      line-height: 30px;
      width: 487px;
      margin-bottom: 32px;
    }
    .sub-heading {
      font-size: 32px;
      margin-bottom: 14px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 64.5%;
      }
    }
    .connectWallet {
      width: 242px;
      padding: 12.5px 10px;
      span {
        font-size: 19.5px;
      }
      img {
        width: 21px;
        margin-right: 9px;
      }
      &:last-child {
        width: 192px;
      }
    }
  }
}
@media (max-width: 1600px) {
  .mainHeader {
    .connectWallet {
      width: 157px;
      padding: 12px 10px;
    }
  }
  .container {
    padding: 0 125px;
  }
  .mainHeader {
    top: 39px;
    .navbar-brand {
      img {
        max-width: 126px;
      }
    }
    ul {
      padding-left: 6px;
      li {
        padding: 0px 17px;
        a {
          font-size: 17px;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }
    }
  }
  .connectWallet {
    border-radius: 10px !important;
  }
  .section-hero {
    .mainTop-content {
      padding-top: 65px;
    }
    .sub-heading {
      font-size: 31px;
      margin-bottom: 13.5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 62.5%;
      }
    }
    p {
      font-size: 17.5px;
      line-height: 28px;
      width: 488px;
      letter-spacing: 0.1px;
      margin-bottom: 32px;
    }
    .connectWallet {
      width: 234px;
      padding: 12.5px 10px;
      span {
        font-size: 19px;
      }
      img {
        width: 20px;
        margin-right: 9px;
        height: auto;
      }
      &:last-child {
        width: 187px;
        margin-left: 9px;
      }
    }
  }
  .section-swap-event {
    padding: 18px 0px 14px 0px;
    &::before {
      background-position: bottom left;
    }
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 77px;
        margin-top: 22px;
      }
      .col-lg-6 {
        .mainImg-join {
          width: 105.5%;
          max-width: 120%;
          margin-left: -20px;
        }
      }
      .joinName {
        font-size: 31px;
        line-height: 21px;
      }
      .heading {
        font-size: 49.5px;
        line-height: 65px;
        margin-bottom: 5px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
      }
      .btn-black {
        font-size: 19px;
        padding: 13px 52px;
        letter-spacing: 1.3px;
        border-radius: 10px;
      }
      .mainImg-join {
        width: 106%;
        margin-left: -18px;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 63px;
        img {
          margin: 12px 0px 8px 0px;
          width: 96%;
        }
        .decentralized {
          font-size: 25.5px;
          line-height: 21px;
        }
        .heading {
          font-size: 49.5px;
          line-height: 53px;
        }
        ul {
          li {
            .sub-heading {
              font-size: 32px;
              line-height: 50px;
            }
            span {
              font-size: 16.5px;
              line-height: 25px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 50px;
      line-height: 62px;
    }
  }
  .section-skin-select {
    .row-skin-select {
      .main-textBox {
        padding-left: 72px;
      }
    }
  }
  .main-section-turboVehicles {
    padding-top: 91px;
    padding-bottom: 94px;
    &::before {
      width: 52%;
    }
    &::before {
      content: "";
      width: 55%;
    }
    .turboVehical-left {
      padding-right: 65px;
    }
    .leftImg {
      img {
        margin: 8px 0px 15px 0px;
        // padding-right: 26px;
      }
      span {
        width: 612px;
        letter-spacing: 0.2px;
      }
    }
    .main-innervehiclesText {
      h2 {
        font-size: 31px;
        line-height: 44px;
      }
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 304px;
          }
        }
        &:nth-child(3) {
          span {
            width: 271px;
          }
        }
      }
    }
    .heading {
      font-size: 49.5px;
      line-height: 60px;
      margin-top: -22px;
    }
    span {
      font-size: 17px;
      line-height: 25px;
    }

    .rightImg {
      // justify-content: flex-end !important;
      // padding-right: 128px;
      padding-left: 0;
      // img {
      //   width: 62.5%;
      // }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 45px;
        line-height: 53px;
        margin: 0px;
      }
      .main-textBox {
        li {
          h4 {
            font-size: 32px;
            line-height: 42px;
          }
          span {
            font-size: 17px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 143px;
  }
}
@media (max-width: 1500px) {
  .container {
    padding: 0 114px;
  }
  .mainHeader {
    top: 33px;
    .navbar-brand {
      img {
        max-width: 122px;
      }
    }
    .connectWallet {
      width: 152px;
      padding: 11px 10px;
      img {
        height: auto !important;
      }
    }
    ul {
      padding-left: 15px;
      li {
        a {
          font-size: 15.5px;
        }
      }
    }
  }
  .connectWallet {
    border-radius: 8px !important;
  }
  .section-hero {
    .mainTop-content {
      padding-top: 62px;
    }
    .sub-heading {
      font-size: 29px;
      margin-bottom: 13.5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 59%;
      }
    }
    p {
      font-size: 16px;
      line-height: 27px;
      width: 488px;
      letter-spacing: 0.4px;
      margin-bottom: 28px;
    }
    .connectWallet {
      width: 223px;
      padding: 10.5px 10px;
      span {
        font-size: 18px;
      }
      img {
        width: 18.5px;
        margin-right: 7px;
        height: auto;
      }
      &:last-child {
        width: 174px;
        margin-left: 9px;
        img {
          width: 18px;
        }
      }
    }
  }
  .section-swap-event {
    padding: 17px 0px 14px 0px;
    &::before {
      background-position: bottom left;
    }
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 65.5px;
        margin-top: 22px;
      }
      .col-lg-6 {
        .mainImg-join {
          width: 105%;
          max-width: 110%;
          margin-left: -15px;
        }
      }
      .joinName {
        font-size: 29.5px;
        line-height: 20px;
      }
      .heading {
        font-size: 47.5px;
        line-height: 62px;
      }
      p {
        font-size: 17px;
        line-height: 27px;
      }
      .btn-black {
        font-size: 18px;
        padding: 12px 49px;
        letter-spacing: 1px;
        border-radius: 9px;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 63px;
        img {
          margin: 11px 0px 8px 0px;
          width: 97.5%;
        }
        .decentralized {
          font-size: 23.5px;
          line-height: 25px;
          display: block;
        }
        .heading {
          font-size: 46.5px;
          line-height: 50px;
        }
        ul {
          li {
            margin-bottom: 17px;
            .sub-heading {
              font-size: 30px;
            }
            span {
              font-size: 15.5px;
              line-height: 24px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 113px;
    .heading {
      font-size: 47px;
      width: 616px;
      line-height: 59px;
    }
  }
  .main-section-turboVehicles {
    padding-top: 107px;
    padding-bottom: 87px;
    &::before {
      width: 50%;
      right: 26px;
    }
    .turboVehical-left {
      margin-top: 20px;
    }
    .leftImg {
      img {
        margin: 4px 0px 13px 0px;
        // padding-right: 26px;
      }
      span {
        width: 575px;
      }
    }

    .main-innervehiclesText {
      h2 {
        font-size: 28.5px;
        line-height: 44px;
      }
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 286px;
          }
        }
        &:nth-child(1) {
          span {
            width: 223px;
          }
        }
        &:nth-child(3) {
          span {
            width: 260px;
          }
        }
      }
    }

    .heading {
      font-size: 46.5px;
      line-height: 60px;
      margin-top: -22px;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
    // .rightImg {
    //   justify-content: flex-end !important;
    //   padding-right: 128px;
    //   img {
    //     width: 63.5%;
    //   }
    // }
  }
  .section-skin-select {
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 82%;
        }
      }

      .heading {
        font-size: 44px !important;
      }
      .main-textBox {
        li {
          margin-bottom: 19px !important;
          h4 {
            font-size: 28px !important;
            line-height: 40px !important;
          }
          span {
            font-size: 16px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
@media (max-width: 1450px) {
  .section-swap-event {
    padding: 15px 0px 14px 0px;
    &::before {
      background-position: bottom left;
    }
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 67px;
        margin-top: 22px;
      }
      .joinName {
        font-size: 28px;
        margin-top: 0px;
      }
      .heading {
        font-size: 45px;
        line-height: 58px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
        width: 500px;
      }
      .btn-black {
        font-size: 17px;
        padding: 11px 48px;
        letter-spacing: 1px;
        border-radius: 9px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .main-textBox {
        padding-left: 66px;
        li {
          span {
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    // .rightImg {
    //   padding-right: 118px;
    // }
    .heading {
      font-size: 44.5px;
      line-height: 58px;
      margin-top: 0px;
    }
    .leftImg {
      span {
        width: 555px;
      }
    }
    span {
      letter-spacing: 0.1px;
      font-size: 15px;
      line-height: 23px;
    }
    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 272px;
          }
        }
        &:nth-child(3) {
          span {
            width: 243px;
          }
        }
        &:nth-child(1) {
          width: 213px;
        }
      }
    }
  }
  .section-hero {
    p {
      font-size: 15.5px;
      line-height: 25.5px;
      width: 442px;
      letter-spacing: 0.3px;
    }
    .sub-heading {
      font-size: 28px;
      margin-bottom: 10.5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 56.5%;
      }
    }
    .connectWallet {
      width: 212px;
      padding: 9.5px 5px;
      span {
        font-size: 17px;
      }
      img {
        width: 18px;
        margin-right: 7px;
        height: auto;
      }
      &:last-child {
        width: 174px;
        margin-left: 9px;
        img {
          width: 17.5px;
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .container {
    padding: 0 108px;
  }
  .mainHeader {
    top: 30px;
    .navbar-brand {
      img {
        max-width: 112px;
      }
    }
    ul {
      padding-left: 16px;
      li {
        a {
          font-size: 14.5px;
        }
      }
    }
    .connectWallet {
      width: 139px;
      padding: 9px 10px;
      span {
        font-size: 14.5px !important;
      }
    }
  }
  .heading {
    font-size: 45px !important;
  }

  .section-swap-event {
    padding: 15px 0px 14px 0px;
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 63px;
        margin-top: 22px;
      }
      .joinName {
        font-size: 26px;
        margin-top: 0px;
      }
      .heading {
        font-size: 43px !important;
        line-height: 56px;
      }
      p {
        font-size: 15px;
        line-height: 24px;
        width: 470px;
      }
      .btn-black {
        font-size: 15.5px;
        padding: 12px 49px;
        border-radius: 8px;
        margin-top: 17px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 43px !important;
      }
      .main-textBox {
        li {
          span {
            font-size: 14px !important;
            line-height: 22px !important;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 43px !important;
      line-height: 55px;
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        .decentralized {
          font-size: 22.5px;
        }
        .heading {
          font-size: 43.5px !important;
          line-height: 47px;
        }
        ul {
          li {
            .sub-heading {
              font-size: 28px;
            }
            span {
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.6px;
              display: block;
            }
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    // .rightImg {
    //   padding-right: 111px;
    // }
    .heading {
      font-size: 43.5px !important;
      line-height: 58px;
      margin-top: 10px;
    }
    .leftImg {
      span {
        width: 530px;
      }
      img {
        // padding-right: 17px;
      }
    }
    span {
      font-size: 14.5px;
      line-height: 21px;
    }
    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 266px;
          }
        }
        &:nth-child(3) {
          span {
            width: 242px;
          }
        }
        &:nth-child(1) {
          width: 200px;
        }
      }
    }
  }
}
@media (max-width: 1350px) {
  .container {
    padding: 0 104px;
  }
  .mainHeader {
    .navbar-brand {
      img {
        max-width: 105px;
      }
    }
    ul {
      padding-left: 16px;
      li {
        padding: 0px 15px;
        a {
          font-size: 14px;
        }
      }
    }
    .connectWallet {
      width: 134px;
      padding: 8px;
      span {
        font-size: 14px !important;
      }
      img {
        width: 20px !important;
      }
    }
  }
  .section-swap-event {
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 60px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 87%;
        }
      }
      .heading {
        font-size: 41px !important;
      }
      .main-textBox {
        li {
          span {
            font-size: 13.5px !important;
            line-height: 21px !important;
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    // .rightImg {
    //   padding-right: 100px;
    //   padding-top: 20px;
    // }
    .heading {
      font-size: 41.5px !important;
      line-height: 58px;
      margin-top: 10px;
    }
    .leftImg {
      span {
        width: 508px;
      }
      img {
        // padding-right: 17px;
      }
    }

    span {
      font-size: 14px;
      line-height: 21px;
    }
    .main-innervehiclesText {
      h2 {
        font-size: 26.5px;
        line-height: 40px;
      }
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 251px;
          }
        }
        &:nth-child(3) {
          span {
            width: 229px;
          }
        }
        &:nth-child(1) {
          width: 196px;
        }
      }
    }
  }
  .section-hero {
    p {
      font-size: 14.5px;
      line-height: 23.5px;
      width: 401px;
    }
    .sub-heading {
      font-size: 26px;
      margin-bottom: 8px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 53%;
      }
    }
    .connectWallet {
      width: 199px;
      padding: 8.5px 5px;
      span {
        font-size: 16px;
      }
      img {
        width: 18px;
        margin-right: 7px;
        height: auto;
      }
      &:last-child {
        width: 157px;
        margin-left: 7px;
        img {
          width: 17.5px;
        }
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        ul {
          li {
            span {
              font-size: 13px;
              line-height: 21px;
              letter-spacing: 0.7px;
              display: block;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 39px !important;
        line-height: 44px !important;
      }
      .main-textBox {
        li {
          span {
            font-size: 13.5px !important;
            line-height: 21px !important;
          }
        }
      }
    }
  }
  .container {
    padding: 0 100px;
  }
  .mainHeader {
    top: 28px;
    .navbar-brand {
      img {
        max-width: 103px;
      }
    }
    ul {
      padding-left: 16px;
      li {
        padding: 0px 14px;
        a {
          font-size: 13.5px;
        }
      }
    }
    .connectWallet {
      width: 130px;
      padding: 7px;
      span {
        font-size: 14px !important;
      }
      img {
        width: 20px !important;
      }
    }
  }
  .connectWallet {
    border-radius: 8px !important;
  }
  .section-hero {
    .mainTop-content {
      padding-top: 58px;
    }
    .sub-heading {
      font-size: 25px;
      margin-bottom: 7px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 51%;
      }
    }
    p {
      letter-spacing: 0px;
    }
    .connectWallet {
      width: 191px;
      padding: 7px 5px;
      span {
        font-size: 15px;
      }
      img {
        width: 16.5px;
        margin-right: 7px;
        height: auto;
      }
      &:last-child {
        width: 157px;
        margin-left: 7px;
        img {
          width: 15.5px;
        }
      }
    }
  }
  .section-swap-event {
    padding: 13px 0px 14px 0px;
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 56.5px;
        margin-top: 22px;
      }
      .col-lg-6 {
        .mainImg-join {
          width: 105%;
          max-width: 110%;
          margin-left: -15px;
        }
      }
      .joinName {
        font-size: 25.5px;
        line-height: 18px;
      }
      .heading {
        font-size: 39.5px !important;
        line-height: 49px;
      }
      p {
        font-size: 15px;
        line-height: 23px;
      }
      .btn-black {
        font-size: 15.5px;
        padding: 10px 42px;
        border-radius: 8px;
        font-weight: 500;
        margin-top: 15px;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 59px;
        img {
          margin: 7px 0px 8px 0px;
          width: 99.5%;
        }
        .decentralized {
          font-size: 20.5px;
          line-height: 22px;
          display: block;
        }
        .heading {
          font-size: 39.5px !important;
          line-height: 45px;
        }
        ul {
          li {
            margin-bottom: 17px;
            .sub-heading {
              font-size: 26px;
              line-height: 42px;
            }
            span {
              font-size: 14px;
              line-height: 21px;
              display: block;
              letter-spacing: 0.2px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 83px;
    .heading {
      font-size: 40px !important;
      width: 588px;
      line-height: 51px;
      margin-bottom: 7px !important;
    }
  }
  .main-section-turboVehicles {
    padding-bottom: 75px;
    &::before {
      content: "";
      width: 50%;
      right: 26px;
    }
    .leftImg {
      img {
        margin: 4px 0px 13px 0px;
        // padding-right: 26px;
      }
      span {
        width: 498px;
      }
    }

    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 249px;
          }
        }
        &:nth-child(3) {
          span {
            width: 220px;
          }
        }
        &:nth-child(1) {
          span {
            width: 189px;
          }
        }
      }
      h2 {
        font-size: 24px;
        line-height: 39px;
      }
    }
    .heading {
      font-size: 39.5px !important;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    span {
      font-size: 13.5px;
      line-height: 20px;
    }
    // .rightImg {
    //   padding-right: 107px;
    //   img {
    //     width: 63%;
    //   }
    // }
  }
}
@media (max-width: 1250px) {
  .container {
    padding: 0px 97px;
  }
  .mainHeader {
    .navbar-brand {
      img {
        max-width: 100px;
      }
    }
    ul {
      padding-left: 16px;
      li {
        padding: 0px 13px;
        a {
          font-size: 13px;
        }
      }
    }
    .connectWallet {
      width: 124px;
      padding: 6px;
      span {
        font-size: 13.5px !important;
      }
      img {
        width: 18px !important;
      }
    }
  }
  .section-swap-event {
    &.section-swap-event::before {
      background-position: 37% 37%;
    }
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 55.5px;
        margin-top: 22px;
      }
      .mainImg-join {
        width: 107%;
      }
      .joinName {
        font-size: 24px;
      }
      .heading {
        font-size: 38.5px !important;
        line-height: 47px;
      }
      p {
        font-size: 14px;
        width: 440px;
        line-height: 22px;
      }
      .btn-black {
        font-size: 14.5px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 40px !important;
      }
      .main-textBox {
        padding-left: 51px;
        li {
          span {
            font-size: 12.5px !important;
            line-height: 18px !important;
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    padding-bottom: 75px;
    .heading {
      font-size: 37.5px !important;
      margin-top: 0px;
      margin-bottom: 5px;
      line-height: 50px;
    }
    .leftImg {
      span {
        width: 471px;
      }
    }

    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 239px;
          }
        }
        &:nth-child(3) {
          span {
            width: 211px;
          }
        }
        &:nth-child(1) {
          span {
            width: 181px;
          }
        }
      }
      h2 {
        font-size: 23px;
        line-height: 36px;
      }
    }

    span {
      font-size: 13px;
      line-height: 19px;
    }
    // .rightImg {
    //   padding-right: 99px;
    //   img {
    //     width: 63%;
    //   }
    // }
  }
  .section-hero {
    .mainTop-content {
      padding-top: 45px;
    }
    .sub-heading {
      font-size: 24px;
      margin-bottom: 5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 49%;
      }
    }
    p {
      width: 371px;
      font-size: 14px;
      line-height: 21.5px;
    }
    .connectWallet {
      width: 184px;
      padding: 6px 5px;
      span {
        font-size: 14.5px;
      }
      img {
        width: 16.5px;
        margin-right: 7px;
        height: auto;
      }
      &:last-child {
        width: 146px;
        margin-left: 7px;
        img {
          width: 15.5px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .mainHeader {
    top: 25px;
    .navbar-brand {
      img {
        max-width: 94px;
      }
    }
    ul {
      padding-left: 10px;
      li {
        padding: 0px 13px;
        a {
          font-size: 12.5px;
        }
      }
    }
    .connectWallet {
      width: 117px;
      padding: 6px;
      span {
        font-size: 13px !important;
      }
      img {
        width: 18px !important;
      }
    }
  }
  .section-hero {
    overflow: hidden;
    &::before {
      left: auto;
      right: 0;
      background-position: right -1%;
      width: 111%;
      height: 132%;
    }
    .mainTop-content {
      padding-top: 45px;
    }
    .sub-heading {
      font-size: 23px;
      margin-bottom: 5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 46.5%;
      }
    }
    p {
      width: 353px;
      font-size: 13px;
      line-height: 20.5px;
      letter-spacing: 0.1px;
      margin-bottom: 25px;
    }
    .connectWallet {
      width: 170px;
      padding: 5px 5px;
      align-items: center !important;
      span {
        font-size: 14px;
      }
      img {
        width: 15px !important;
        margin-right: 6px !important;
        height: auto !important;
      }
      &:last-child {
        width: 144px;
        margin-left: 7px;
        img {
          width: 14.5px !important;
        }
      }
    }
  }
  .section-skin-select {
    &::before {
      left: 41px;
    }
  }
  .connectWallet img {
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px !important;
  }
  .section-swap-event {
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 52px;
      }
      .mainImg-join {
        width: 107%;
      }
      .joinName {
        font-size: 23px;
      }
      .heading {
        font-size: 37.5px !important;
        line-height: 47px;
      }
      p {
        font-size: 13.5px;
        line-height: 21px;
      }
      .btn-black {
        font-size: 14px;
        padding: 10px 39px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .main-textBox {
        list-style: none;
      }
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 83% !important;
        }
      }
      .heading {
        font-size: 35px !important;
        font-weight: 600;
      }
      .main-textBox {
        li {
          margin-bottom: 13px !important;
          h4 {
            font-size: 26px !important;
            line-height: 36px !important;
          }
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 30px;
      }
    }
  }
  .main-section-turboVehicles {
    .turboVehical-left {
      padding-right: 30px;
    }

    .heading {
      font-size: 36.5px !important;
    }
    .leftImg {
      img {
        // padding-right: 42px;
      }
      span {
        width: 451px;
      }
    }

    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 219px;
          }
        }
        &:nth-child(3) {
          span {
            width: 211px;
          }
        }
        &:nth-child(1) {
          span {
            width: 181px;
          }
        }
      }
    }

    span {
      font-size: 12.5px;
      line-height: 18.5px;
    }
    // .rightImg {
    //   padding-right: 90px;
    //   padding-top: 0px;
    //   img {
    //     width: 63%;
    //   }
    // }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        .decentralized {
          font-size: 18.5px;
          line-height: 22px;
          display: block;
        }
        .heading {
          font-size: 35.5px !important;
          line-height: 41px;
        }
        ul {
          li {
            margin-bottom: 15px;
            .sub-heading {
              font-size: 23px;
              line-height: 38px;
            }
            span {
              font-size: 13px;
              line-height: 20px;
              display: block;
              letter-spacing: 0.3px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 37px !important;
      width: 489px;
      line-height: 48px;
    }
  }
}
@media (max-width: 1100px) {
  .mainHeader {
    top: 23px;
    .navbar-brand {
      img {
        max-width: 85px;
      }
    }
    ul {
      padding-left: 10px;
      li {
        padding: 0px 12px;
        a {
          font-size: 11.5px;
        }
      }
    }
    .connectWallet {
      width: 112px;
      padding: 4px;
      span {
        font-size: 11.5px !important;
      }
      img {
        width: 16px !important;
        margin-right: 6px !important;
      }
    }
  }
  .section-hero {
    &::before {
      left: auto;
      right: 0;
      background-position: right 66px;
      width: 107%;
      height: 100%;
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 35px !important;
      }

      .main-textBox {
        padding-left: 46px;
        li {
          h4 {
            font-size: 23px !important;
          }
          span {
            font-size: 11px !important;
            line-height: 16px !important;
          }
        }
      }
    }
  }
  .container {
    padding: 0 82px;
  }

  .connectWallet {
    border-radius: 8px !important;
    span {
      font-size: 12px !important;
      letter-spacing: 0px;
    }
    img {
      width: 16px !important;
      height: 14px !important;
      margin-right: 4px !important;
    }
  }
  .section-hero {
    .mainTop-content {
      padding-top: 51px;
    }
    .sub-heading {
      font-size: 22px;
      margin-bottom: 10.5px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 44%;
      }
    }
    p {
      font-size: 13px;
      line-height: 20px;
      width: 366px;
      letter-spacing: 0px;
      margin-bottom: 19px;
    }
    .connectWallet {
      width: 144px;
      padding: 5px 5px !important;
      span {
        font-size: 13px !important;
        font-weight: 400 !important;
      }
      &:last-child {
        margin-left: 8px;
      }
      + .connectWallet {
        img {
          width: 18px !important;
          height: 15px !important;
        }
      }
    }
  }
  .section-swap-event {
    padding: 13px 0px 9px 0px;
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 44.5px;
        margin-top: 22px;
      }
      .col-lg-6 {
        .mainImg-join {
          margin-left: -9px;
        }
      }
      .joinName {
        font-size: 21.5px;
        line-height: 18px;
      }
      .heading {
        font-size: 34.5px !important;
        line-height: 41px;
      }
      p {
        font-size: 12.5px;
        line-height: 19px;
        width: 390px;
      }
      .btn-black {
        font-size: 13px;
        padding: 8px 36px;
        border-radius: 6px;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 50px;
        .decentralized {
          font-size: 17.5px;
          line-height: 22px;
          display: block;
        }
        .heading {
          font-size: 34px !important;
          line-height: 35px;
        }
        ul {
          li {
            margin-bottom: 17px;
            .sub-heading {
              font-size: 21.5px;
              line-height: 30px;
            }
            span {
              font-size: 11.5px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 88px;
    .heading {
      font-size: 34px !important;
      width: 500px;
      line-height: 43px;
      margin-bottom: 7px !important;
    }
  }
  .main-section-turboVehicles {
    padding-bottom: 70px;
    padding-top: 73px;
    .leftImg {
      span {
        width: 426px;
      }
    }
    .turboVehical-left {
      padding-right: 41px;
    }
    .main-innervehiclesText {
      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 206px;
          }
        }
        &:nth-child(3) {
          span {
            width: 188px;
          }
        }
        &:nth-child(1) {
          span {
            width: 181px;
          }
        }
      }
      h2 {
        font-size: 21.5px;
        line-height: 34px;
      }
    }
    .heading {
      font-size: 34px !important;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    span {
      font-size: 11.5px;
      line-height: 17px;
    }
    // .rightImg {
    //   padding-right: 0px;
    //   padding-left: 40px;
    //   justify-content: center !important;
    //   img {
    //     width: 55%;
    //   }
    // }
  }
}
@media (max-width: 1050px) {
  .section-swap-event {
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 44.5px;
        margin-top: 22px;
      }
      .mainImg-join {
        margin-left: -12px;
      }
      .col-lg-6 {
        .mainImg-join {
          margin-left: -9px;
        }
      }
      .joinName {
        font-size: 20.5px;
        line-height: 18px;
      }
      .heading {
        font-size: 32.5px !important;
        line-height: 38px;
      }
      p {
        font-size: 12px;
        line-height: 19px;
        width: 390px;
      }
      .btn-black {
        font-size: 12px;
        padding: 8px 35px;
        border-radius: 5px;
        margin-top: 12px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .leftPhone-screen {
        .chnageImg-mobile {
          width: 86% !important;
        }
      }
      .main-textBox {
        padding-left: 39px;
        li {
          h4 {
            font-size: 21px !important;
          }
          span {
            font-size: 10.5px !important;
            line-height: 15px !important;
          }
        }
      }
      .heading {
        font-size: 32px !important;
      }
    }
  }
  .main-section-turboVehicles {
    .leftImg {
      span {
        width: 400px;
        margin-bottom: 9px;
      }
    }
    .turboVehical-left {
      padding-right: 41px;
    }
    .main-innervehiclesText {
      .subText {
        margin-bottom: 21px;
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 200px;
          }
        }
        &:nth-child(3) {
          span {
            width: 188px;
          }
        }
        &:nth-child(1) {
          span {
            width: 181px;
          }
        }
      }
      h2 {
        font-size: 20.5px;
        line-height: 33px;
      }
    }
    .heading {
      font-size: 32px !important;
    }
    span {
      font-size: 11px;
      line-height: 16px;
    }
    // .rightImg {
    //   img {
    //     width: 56%;
    //   }
    // }
  }
  .section-hero {
    .mainTop-content {
      padding-top: 42px;
    }
    .sub-heading {
      font-size: 20px;
      margin-bottom: 2px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 41%;
      }
    }
    p {
      width: 309px;
      font-size: 11.5px;
      line-height: 18.5px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      letter-spacing: 0.1px;
    }
    .connectWallet {
      width: 155px;
      padding: 0px 5px;
      height: 37px;
      span {
        font-size: 12.5px;
        display: block;
      }
      img {
        width: 13.5px !important;
        margin-right: 6px !important;
        height: auto !important;
      }
      &:last-child {
        width: 126px;
        margin-left: 6px;
        span {
          display: block;
        }
        img {
          width: 12px !important;
        }
      }
    }
  }
}
@media (max-width: 991.98px) {
  .container {
    padding: 0px 70px;
  }
  .section-road-report {
    padding-top: 0px !important;
  }
  .mainHeader {
    top: 12px;
    .connectWallet {
      width: auto;
    }
  }
  .section-hero {
    background-color: #171818;
    padding-top: 0px;
    padding-bottom: 0px;

    .turboHiro {
      .lg-blockLogo {
        max-width: 76%;
      }
    }
    .row-hero-section {
      .hiro-rightMobile {
        padding-right: 0;
        justify-content: center !important;
      }
      .col-lg-5 {
        order: 2;
      }
      .col-lg-7 {
        order: 1;
      }
      .sm-dBlock-img {
        display: block;
      }
      .lg-dBlock-img {
        display: none;
      }

      .textBlok-left {
        // text-align: center;
        max-width: 386px;
        margin: 0px auto auto auto;
      }
    }
    .sub-heading {
      font-size: 18.5px;
      margin-bottom: 3px;
    }

    p {
      width: 295px;
      font-size: 11px;
      line-height: 17.5px;
      letter-spacing: 0px;
      margin-bottom: 18px;
    }
    .connectWallet {
      width: 152px;
      padding: 0px 5px;
      height: 35px;
      border-radius: 6px !important;
      span {
        font-size: 12px !important;
        display: block;
      }
      img {
        width: 13px !important;
        margin-right: 6px !important;
        height: auto !important;
      }
      &:last-child {
        width: 118px;
        margin-left: 6px;
        span {
          display: block;
        }
        img {
          width: 12px !important;
        }
      }
    }
  }

  .section-swap-event {
    padding: 10px 0px 10px 0px;
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 40px;
      }
      .mainImg-join {
        width: 105%;
        margin-left: -4px;
      }
      .joinName {
        font-size: 19px;
        line-height: 16px;
      }
      .heading {
        font-size: 31px !important;
        line-height: 36px;
      }
      p {
        font-size: 11px;
        line-height: 18px;
        width: 344px;
      }
      .btn-black {
        font-size: 11px;
        padding: 9px 34px;
        border-radius: 6px;
        margin-top: 10px;
      }
    }
  }
  .section-skin-select {
    padding: 50px 0px 0px 0px;
    &::before {
      content: "";
      left: 36px;
      background-position: center left;
      background-size: contain;
      width: 52%;
    }
    .row-skin-select {
      .main-textBox {
        li {
          margin-bottom: 5px !important;
          h4 {
            font-size: 20px !important;
          }
        }
      }
      .heading {
        // text-align: center;
        font-size: 32px !important;
        margin-top: 50px;
      }
      .leftPhone-screen img {
        width: 80%;
      }
    }
  }

  .section-road-report {
    .row-road-report {
      .decentralized-block {
        .decentralized {
          font-size: 15.5px;
          line-height: 21px;
        }
        .heading {
          font-size: 31px !important;
          line-height: 30px;
        }
        ul {
          li {
            .sub-heading {
              font-size: 20px;
              line-height: 28px;
            }
            span {
              font-size: 10.5px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 80px;
    .heading {
      margin: auto auto 0px auto;
      width: 400px;
      font-size: 30px !important;
      line-height: 37px;
    }
  }
  .main-section-turboVehicles {
    padding-bottom: 57px;
    padding-top: 60px;
    .turboVehical-left {
      padding-left: 0px;
    }
    .heading {
      font-size: 30px !important;
      line-height: 32px;
    }
    .leftImg {
      span {
        width: 100%;
      }
    }
    .main-innervehiclesText {
      h2 {
        font-size: 19.5px;
        line-height: 27px;
      }

      .subText {
        &:nth-child(2),
        &:nth-child(4) {
          span {
            width: 100%;
          }
        }
        &:nth-child(3) {
          span {
            width: 100%;
          }
        }
        &:nth-child(1) {
          span {
            width: 100%;
          }
        }
      }
      h2 {
        font-size: 21.5px;
        line-height: 34px;
      }
    }
  }
}
@media (max-width: 840px) {
  .section-swap-event {
    padding: 9px 0px 8px 0px;
    .row-private-swap-event {
      .rightPrivate-text {
        padding-left: 31px;
      }
      .mainImg-join {
        width: 105%;
        margin-left: -4px;
      }
      .joinName {
        font-size: 16px;
        line-height: 14px;
      }
      .heading {
        font-size: 25px !important;
        line-height: 30px;
      }
      p {
        font-size: 10px;
        line-height: 14px;
        width: 314px;
      }
      .btn-black {
        font-size: 10px;
        padding: 6px 22px;
        border-radius: 4px;
        margin-top: 11px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        font-size: 28px !important;
        margin-bottom: -6px;
        line-height: 30px !important;
      }
    }
  }
}
@media (max-width: 767.98px) {
  // ----loder------
  .mainLoder-section {
    .inner-box {
      padding: 80px 0px 50px 0px;
      .loder-logo {
        max-width: 60%;
        margin-bottom: 15px;
      }
      h2 {
        font-size: 28px;
      }
    }
  }
  //---/loder----
  .container {
    padding: 0px 42px;
  }
  .mainHeader {
    top: 23px;
  }
  .heading {
    font-size: 46px !important;
  }
  .section-hero {
    .sub-heading {
      font-size: 14.5px;
      line-height: 24px;
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 60%;
      }
    }
    p {
      width: 240px;
      font-size: 9px;
      line-height: 14.5px;
      letter-spacing: 0;
      margin-bottom: 15px;
    }
    .connectWallet {
      width: 143px;
      height: 30px;
      span {
        font-size: 10px !important;
      }
      img {
        width: 10px !important;
      }
      &:last-child {
        width: 98px;
        img {
          width: 9px !important;
          height: auto !important;
        }
      }
    }
  }
  .section-swap-event {
    padding: 25px 0px;
    .row-private-swap-event {
      .mainImg-join {
        width: 100%;
        margin-left: 0;
      }
      .rightPrivate-text {
        text-align: center;
        max-width: 500px;
        margin: 10px auto auto auto;
        padding-left: 0px;
      }
      .joinName {
        line-height: 23px;
        font-size: 20px;
      }
      .heading {
        font-size: 36.5px !important;
        line-height: 36px !important;
      }
      p {
        font-size: 15px;
        line-height: 22px;
        width: 100%;
      }
      .btn-black {
        font-size: 15px;
        padding: 8px 28px;
        border-radius: 8px;
        margin-top: 16px;
        margin-bottom: 8px;
      }
    }
  }
  .mainSticky-section {
    min-height: auto !important;
    .section-skin-select {
      position: inherit !important;
      height: auto !important;
    }
  }
  .section-road-report {
    padding-top: 60px !important;
    .decentralized-block {
      padding-right: 0;
      margin-top: 60px;
    }
    .col-md-6 {
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
  }
  .main-section-turboVehicles {
    padding-top: 63px;
  }
  .section-skin-select {
    overflow-x: hidden;
    &::before {
      display: none;
    }
    .row-skin-select {
      .section-skinImg {
        width: 81%;
      }
      .chnageImg-mobile {
        width: 70% !important;
        margin: auto;
        display: block;
        position: relative;
        z-index: 11;
      }
      .main-textBox {
        text-align: center;
      }
      .heading {
        font-size: 31px !important;
        margin-bottom: 35px;
        line-height: 35px !important;
        margin-top: 0px;
        z-index: 1;
        position: relative;
      }
      .main-textBox {
        padding-left: 0px !important;
        li {
          margin-bottom: 35px !important;
          position: relative;
          &::before {
            content: "";
            background-image: url("../../public/Assets/skin-select-bg.png");
            position: absolute;
            bottom: 0px;
            right: 0;
            background-repeat: no-repeat;
            z-index: 0;
            height: 100%;
            left: 50%;
            background-position: center top;
            background-size: contain;
            width: 114%;
            top: -40px;
            transform: translateX(-50%);
          }
          h4 {
            font-size: 22px !important;
            margin-top: 15px;
            position: relative;
            z-index: 11;
          }
          span {
            font-size: 17px !important;
            line-height: 25px !important;
            position: relative;
            z-index: 11;
          }
          &:last-child {
            margin-bottom: 0px !important;
          }
          &:first-child {
            margin-bottom: 0px !important;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 60px;
    .heading {
      margin: auto auto 30px auto;
      width: 100%;
      max-width: 500px;
      font-size: 36px !important;
      line-height: 50px !important;
    }
  }
  .main-section-turboVehicles {
    padding-bottom: 10px;
    padding-top: 64px;
    &::before {
      content: "";
      background-position: top center;
      width: 80%;
      right: 71px;
      transform: translateX(19px);
    }
    .heading {
      line-height: 56px;
    }
    .row {
      .col-md-6 {
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
      }
    }
    .turboVehical-left {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 0px;
    }
    span {
      font-size: 13px;
      line-height: 23px;
    }
    // .rightImg {
    //   img {
    //     width: 50%;
    //   }
    // }
  }

  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding-right: 0px;

        ul {
          li {
            span {
              font-size: 14.5px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  // .section-hero {
  //   .row-hero-section {
  //     .textBlok-left {
  //       max-width: 100%;
  //       margin: -83px auto auto auto;
  //     }
  //   }
  // }
  .section-hero {
    padding-bottom: 60px;
    .mainTop-content {
      position: inherit;
      padding-top: 0;
      background: #171818;
    }
    .sub-heading {
      margin-top: 0;
      font-size: 20px;
      line-height: 35px;
    }
    p {
      width: 100%;
      font-size: 16px;
      line-height: 26.5px;
      margin-bottom: 25px;
    }
    .connectWallet {
      width: 50% !important;
      height: auto !important;
      padding: 9px 10px !important;
      span {
        font-size: 16px !important;
      }
      img {
        width: 18px !important;
        margin-right: 8px !important;
      }
      &:last-child {
        img {
          width: 16px !important;
          margin-right: 8px !important;
        }
      }
    }
    .row-hero-section {
      .hiro-rightMobile {
        .hiro-dBlock-img {
          width: 100%;
        }
      }
      .textBlok-left {
        max-width: 500px;
        text-align: center;
        margin: -83px auto auto auto;
      }
    }
    .turboHiro {
      .lg-blockLogo {
        max-width: 75%;
      }
    }
    .flw-Col {
      justify-content: center !important;
    }
  }
  .section-carplay {
    padding-top: 57px;
  }
  .heading {
    max-width: 100%;
    font-size: 32px !important;
    line-height: 41px !important;
  }
  .main-section-turboVehicles {
    &::before {
      width: 100%;
      right: 0;
      transform: translateX(0px);
    }
    .rightImg {
      padding-right: 0px;
      padding-left: 0px;
      justify-content: center !important;
    }
  }
}
@media (max-width: 500px) {
  // ----loder------
  .mainLoder-section {
    .inner-box {
      padding: 50px 0px 120px 0px;
      .loder-logo {
        max-width: 63%;
        margin-bottom: 15px;
      }
      h2 {
        font-size: 22px;
      }
    }
  }
  //---/loder----
  .container {
    padding: 0px 42px;
  }
  .heading {
    font-size: 35px !important;
  }
  .connectWallet {
    padding: 11px 18px !important;
    border-radius: 9px !important;
    span {
      font-size: 18px !important;
      line-height: 28px !important;
      font-weight: 400 !important;
    }
  }
  .mainHeader .navbar-brand img {
    max-width: 160px;
  }
  .section-hero {
    background-color: #141515;
    padding-bottom: 70px;
    &::before {
      background-position: 0px -6px;
      width: 101%;
      height: 80%;
    }
    .connectWallet {
      width: 50%;
      padding: 11px 5px !important;
      span {
        font-size: 17px !important;
      }
    }
    .col-lg-7 {
      padding: 0px !important;
    }
    .sm-blockLogo {
      display: block;
    }
    .lg-blockLogo {
      display: none;
    }
    .connectWallet {
      &:last-child {
        margin-left: 12px;
      }
    }
    p {
      font-size: 15px;
      line-height: 23px;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 25px;
    }
    .turboHiro {
      img {
        max-width: 100%;
      }
    }
    .row-hero-section {
      .textBlok-left {
        text-align: center;
        max-width: 100%;
        margin: -61px auto auto auto;
      }
    }
    .sub-heading {
      font-size: 22px;
      line-height: 34px;
      margin-bottom: 7px;
    }
  }
  .section-swap-event {
    padding: 15px 0px 50px 0px;
    .row-private-swap-event {
      .heading {
        font-size: 35.5px !important;
        line-height: 36px !important;
      }
      .joinName {
        margin-bottom: 0 !important;
      }
      p {
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }
  .section-road-report {
    padding-top: 0px !important;
    .row-road-report {
      .decentralized-block {
        .heading {
          line-height: 38px !important;
          font-size: 36px !important;
        }
        ul {
          li {
            span {
              line-height: 23px !important;
            }
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    .heading {
      font-size: 34px !important;
    }
  }
  .section-swap-event {
    &::before {
      background-image: url("../../public/Assets/mobile-private-swap-bg.png");
      top: inherit;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-position: bottom right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
    .mobileRound-1,
    .mobileRound-2,
    .mobileRound-3 {
      display: block;
      width: 18px;
      height: 18px;
    }
    .mobileRound-1 {
      margin-left: 58px;
    }
    .rightPrivate-text {
      position: relative;
    }
    .mobileRound-2,
    .mobileRound-3 {
      position: absolute;
    }
    .mobileRound-2 {
      right: 21px;
      top: -9px;
    }
    .mobileRound-3 {
      left: -6px;
      bottom: 10px;
    }
    .heading {
      line-height: 24px !important;
    }
    .row-private-swap-event {
      .col-lg-6 {
        &:nth-child(1) {
          padding: 0px;
        }
      }
      .rightPrivate-text {
        max-width: 412px;
        margin: 20px auto auto auto;
      }
      .joinName {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 7px;
      }
      p {
        font-size: 15px;
        line-height: 22px;
      }
      .btn-black {
        font-size: 18px;
        padding: 12px 33px;
        border-radius: 6px;
        margin-bottom: 5px;
        letter-spacing: 0.8px;
        margin-top: 10px;
      }
      .mainImg-join {
        width: 107%;
        margin-left: -10px;
      }
    }
  }
  .main-section-turboVehicles {
    .leftImg {
      img {
        margin: 12px 0px 10px 0px;
        padding-right: 0px;
      }
    }
  }
  .section-skin-select {
    padding: 41px 0px 102px 0px;
    &::before {
      left: auto;
      width: 100%;
      top: 74px;
      transform: translateX(0%);
    }

    .row-skin-select {
      .section-skinImg {
        width: 93%;
      }
      .chnageImg-mobile {
        width: 93% !important;
        margin: auto;
        display: block;
      }

      .heading {
        margin-top: 22px;
        font-size: 25px !important;
        line-height: 40px !important;
        margin-bottom: 16px !important;
        text-align: center;
      }
      .main-textBox {
        .mobile-textData {
          text-align: center;
          margin: 20px 0px 0px 0px;
        }
        li {
          margin-bottom: 40px !important;
          &::before {
            width: 144%;
          }
          h4 {
            font-size: 24px;
            line-height: 30px;
          }
          span {
            font-size: 15px !important;
            line-height: 22px !important;
          }
          &:last-child {
            margin-bottom: 0px !important;
          }
          &:first-child {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }

  .section-road-report {
    .container {
      padding: 0px 15px !important;
    }
    .row-road-report {
      .decentralized-block {
        padding: 0px 27px !important;
        margin-top: 42px;
        .decentralized {
          font-size: 18px;
          line-height: normal;
        }
        .heading {
          line-height: 32px !important;
        }
        img {
          width: 100%;
          margin: 10px 0px 8px 0px;
        }
        ul {
          li {
            margin-bottom: 10px;
            .sub-heading {
              font-size: 22px;
              line-height: 34px;
            }
            span {
              font-size: 15.2px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 86px;
    .heading {
      font-size: 31px !important;
      line-height: 40px !important;
      margin: auto auto 26px auto;
    }
  }
  .main-section-turboVehicles {
    padding-top: 64px;
    padding-bottom: 15px;

    &::before {
      background-position: 16px 6%;
      width: 108%;
    }
    .turboVehical-left {
      margin-top: 40px;
    }
    .heading {
      margin-bottom: 0px;
      margin-top: 12px;
    }
    .leftImg {
      .turboTitle-img {
        margin: 0px 0px 15px 0px;
      }

      span {
        font-size: 14.5px;
        line-height: 21px;
        margin-bottom: 17px;
      }
    }
    // .rightImg {
    //   img {
    //     width: 57%;
    //   }
    // }
    .main-innervehiclesText {
      .subText:nth-child(1),
      .subText:nth-child(3) {
        padding-right: 20px;
      }
      h2 {
        font-size: 22px;
        line-height: 34px;
      }
      .subText {
        margin-bottom: 20px;
        span {
          font-size: 12px !important;
          line-height: 19px !important;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .section-hero {
    .connectWallet {
      padding: 11px 6px !important;
      span {
        font-size: 15px !important;
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 27.5px !important;
    }
  }
}
@media (max-width: 420px) {
  .mainHeader {
    top: 40px;
    .navbar-brand {
      img {
        max-width: 135px;
      }
    }
    .mobileMenu {
      span {
        width: 30px;
        height: 4px;
        &:nth-child(2) {
          width: 17px;
        }
      }
      + span {
        margin-top: 6.6px;
      }
    }
  }
  .container {
    padding: 0px 37px;
  }
  .section-hero {
    padding-top: 0px;
    padding-bottom: 57px;
    .row-hero-section {
      .textBlok-left {
        margin: -53px auto auto auto;
      }
    }
    .sub-heading {
      font-size: 18.5px;
      margin-bottom: 3px;
    }
    p {
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 23px;
    }
    .flw-Col {
      align-items: flex-end !important;
    }
    .connectWallet {
      padding: 7px 6px !important;
      border-radius: 6px !important;
      span {
        font-size: 14px !important;
        font-weight: 300 !important;
        letter-spacing: 0.5px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .section-swap-event {
    padding: 12px 0px 40px 0px;
    &::before {
      background-position: bottom left;
    }
    .heading {
      line-height: 34px !important;
      font-size: 30px !important;
      margin-top: -2px;
      margin-bottom: 5px !important;
    }

    .row-private-swap-event {
      .rightPrivate-text {
        margin: 15px auto auto auto;
      }
      .joinName {
        font-size: 19px;
        margin-bottom: 0;
      }
      p {
        font-size: 12.5px;
        line-height: 18px;
      }
      .btn-black {
        font-size: 15px;
        font-weight: 400;
        padding: 9px 27px;
        margin-bottom: 5px;
        margin-top: 15px;
        letter-spacing: 1px;
        border-radius: 5px;
      }
    }
    .mobileRound-1,
    .mobileRound-2,
    .mobileRound-3 {
      width: 16px;
      height: 16px;
    }
    .mobileRound-1 {
      margin-left: 59px;
      margin-top: 4px;
    }
    .mobileRound-2 {
      right: 16px;
      top: -6px;
    }
    .mobileRound-3 {
      left: -6px;
      bottom: 2px;
    }
  }
  .section-skin-select {
    padding: 36px 0px 83px 0px;
    .row-skin-select {
      .heading {
        margin-top: 21px;
        font-size: 21px !important;
        line-height: 32px !important;
        margin-bottom: 12px !important;
        font-weight: 500;
      }
      .main-textBox {
        li {
          margin-bottom: 30px !important;
          h4 {
            font-size: 20px !important;
            line-height: 32px !important;
          }
          span {
            font-size: 12px !important;
            line-height: 18px !important;
          }
        }
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding: 0px 22px !important;
        margin-top: 22px;
        .decentralized {
          font-size: 15.5px;
          font-weight: 500;
        }
        .heading {
          line-height: 26px !important;
          font-size: 29px !important;
        }
        img {
          margin: 5px 0px 10px 0px;
        }
        ul {
          li {
            .sub-heading {
              font-size: 19px;
              line-height: 22px;
            }
            span {
              font-size: 13px;
              line-height: 20px !important;
              display: block;
            }
          }
        }
      }
    }
  }
  .section-carplay {
    padding-top: 69px;
    .heading {
      font-size: 27px !important;
      line-height: 37px !important;
      margin: auto auto 16px auto;
    }
  }

  .main-section-turboVehicles {
    .heading {
      font-size: 29px !important;
      line-height: 49px !important;
    }
    span {
      font-size: 12.5px;
      line-height: 19px;
      margin-bottom: 12px !important;
    }
    .leftImg {
      img {
        margin: 5px 0px 9px 0px;
      }
    }
    .main-innervehiclesText {
      h2 {
        font-size: 18px;
        line-height: 28px;
      }
      .subText {
        margin-bottom: 13px;
        span {
          font-size: 11px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .container {
    padding: 0px 30px;
  }
  .section-hero {
    p {
      font-size: 12px;
    }
    .connectWallet {
      // padding: ;
      span {
        font-size: 13px !important;
        letter-spacing: 0px;
      }
      img {
        width: 15px !important;
        margin-right: 6px !important;
      }
      &:last-child {
        img {
          width: 14px !important;
          margin-right: 6px !important;
        }
      }
    }
  }
  .section-carplay {
    .heading {
      font-size: 24px !important;
      line-height: 33px !important;
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        padding: 0px 15px !important;
        margin-top: 22px;
      }
    }
  }
  .section-swap-event {
    padding: 5px 0px 40px 0px;
    .mobileRound-1 {
      margin-left: 49px;
      margin-top: 4px;
    }
    .row-private-swap-event {
      .heading {
        font-size: 28.5px !important;
        line-height: 32px !important;
      }
      .mainImg-join {
        width: 104%;
        margin-left: -3px;
      }
      .btn-black {
        margin-top: 5px;
      }
    }
  }
  .section-skin-select {
    .row-skin-select {
      .main-textBox {
        li {
          span {
            font-size: 11.5px !important;
            line-height: 18px !important;
          }
        }
      }
    }
  }

  .main-section-turboVehicles {
    .leftImg {
      span {
        font-size: 12px;
        line-height: 19px;
      }
    }
    .heading {
      font-size: 27px !important;
      line-height: 46px !important;
      margin-top: 0px;
    }
    .turboVehical-left {
      margin-top: 35px;
    }
    .main-innervehiclesText {
      .subText {
        span {
          font-size: 10px !important;
          line-height: 16px !important;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .container {
    padding: 0px 20px;
  }
  .mainHeader {
    top: 34px;
  }
  .main-section-turboVehicles {
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .section-swap-event {
    padding: 10px 0px 33px 0px;
    .row-private-swap-event {
      .heading {
        font-size: 26px !important;
        line-height: 35px !important;
        margin-bottom: 0px !important;
      }
      .joinName {
        line-height: 12px;
        font-size: 17px;
        margin-top: 10px;
        display: block;
      }
      p {
        font-size: 11.5px;
        line-height: 18px;
        max-width: 310px;
        margin: auto;
      }
      .mainImg-join {
        width: 100%;
        margin-left: 0;
      }
      .btn-black {
        padding: 7px 25px;
        margin-top: 15px;
        span {
          font-size: 13px;
        }
      }
      .mobileRound-1,
      .mobileRound-2,
      .mobileRound-3 {
        width: 14px;
        height: 14px;
      }
      .mobileRound-1 {
        margin-left: 56px;
      }
      .mobileRound-2 {
        right: 28px;
        top: -7px;
      }
      .mobileRound-3 {
        left: 6px;
        bottom: 7px;
      }
    }
  }
  .main-section-turboVehicles {
    .heading {
      font-size: 25px !important;
      line-height: 39px !important;
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        .heading {
          font-size: 26px !important;
        }
        ul {
          li {
            span {
              font-size: 11px;
              line-height: 16px !important;
              letter-spacing: 0.2px;
              padding-right: 11px;
            }
            .sub-heading {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
  .mainHeader {
    .mobileMenu {
      span {
        width: 25px;
        height: 4px;
        &:nth-child(2) {
          width: 15px;
        }
      }
    }
    .collapsed {
      span {
        + span {
          // margin-top: 5px !important;
        }
      }
    }
    .navbar-brand {
      img {
        max-width: 117px;
      }
    }
  }
  .section-hero {
    padding-bottom: 55px;
    .row-hero-section {
      .textBlok-left {
        margin: -51px auto auto auto;
      }
    }
    .sub-heading {
      font-size: 16.5px;
      margin-bottom: 0px;
    }
    p {
      font-size: 11.5px;
      line-height: 17px;
    }
    .turboHiro {
      img {
        max-width: 94%;
        margin: auto;
      }
    }
    .connectWallet {
      height: 38px !important;
      img {
        width: 13px !important;
        margin-bottom: 4px;
      }
      span {
        font-size: 13px !important;
      }
      &:last-child {
        img {
          width: 12px !important;
          margin-bottom: 4px;
        }
      }
    }
  }
  .section-skin-select {
    padding: 16px 0px 75px 0px;
    .row-skin-select {
      .chnageImg-mobile {
        width: 86% !important;
      }
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        margin-top: 33px;
        padding: 0px 15px !important;
        .decentralized {
          font-size: 13px;
          margin-bottom: 2px;
        }
      }
    }
  }
  .section-carplay {
    padding-top: 53px;
    .heading {
      font-size: 25px !important;
      line-height: 36px !important;
      margin: auto auto 17px auto;
    }
  }
  .section-skin-select {
    .row-skin-select {
      .heading {
        line-height: 30px !important;
        margin-top: 18px;
        font-size: 18px !important;
        margin-bottom: 5 !important;
        font-weight: 500;
      }
      .main-textBox {
        li {
          h4 {
            font-size: 19px !important;
            line-height: 24px !important;
          }
          span {
            font-size: 11px !important;
            line-height: 17px !important;
          }
        }
      }
    }
  }
  .main-section-turboVehicles {
    padding-top: 59px;
    padding-bottom: 49px;
    .leftImg {
      .turboTitle-img {
        margin: 7px 0px 15px 0px;
      }
      span {
        font-size: 11px;
        line-height: 18px;
      }
    }
    // .rightImg {
    //   img {
    //     width: 54%;
    //   }
    // }
    .main-innervehiclesText {
      h2 {
        font-size: 17px !important;
        font-size: 19px;
      }
      .subText {
        span {
          font-size: 9.5px !important;
          line-height: 15px !important;
        }
      }
    }
  }
}
@media (max-width: 350px) {
  .section-hero {
    .smWidthFull {
      flex-direction: column;
    }
  }
  .section-road-report {
    .row-road-report {
      .decentralized-block {
        ul {
          li {
            span {
              letter-spacing: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 325px) {
  .section-hero {
    .flw-Col {
      flex-direction: column;
    }
    .connectWallet {
      width: 100% !important;
      &:last-child {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
}
