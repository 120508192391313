* {
  outline: 0 !important;
}
body {
  position: relative;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "sfPro";
}
html a {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none;
}
html a:hover {
  text-decoration: none;
}

svg,
img {
  max-width: 100%;
  height: auto;
  width: auto;
}
html .container {
  //max-width: 1440px;
  position: relative;
}

p {
  margin-bottom: 0px;
}
p:last-child {
  margin-bottom: 0;
}

::selection {
  color: #fff;
  background: #0085ff;
}
b,
strong {
  font-weight: 600;
}
button:focus {
  box-shadow: none;
}
.connectWallet {
  border-radius: 12px;
  background-color: rgba(2, 114, 241, 0.25098039215686274);
  border: 0px;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 600;
  border: 1px solid #0272f1;
  span {
    display: block;
    font-size: 21px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 500;
  }
 
}
