@font-face {
    font-family: "sfPro";
    src: local("SF-Pro-Text-Light"), local("SF-Pro-Text-Light"), url("SF-Pro-Text-Light.otf");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "sfPro";
    src: local("SF-Pro-Text-Regular"), local("SF-Pro-Text-Regular"), url("SF-Pro-Text-Regular.otf");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "sfPro";
    src: local("SF-Pro-Text-Medium"), local("SF-Pro-Text-Medium"), url("SF-Pro-Text-Medium.otf");
    font-weight:500;
    font-style: normal;
  }
  @font-face {
    font-family: "sfPro";
    src: local("SF-Pro-Text-Semibold"), local("SF-Pro-Text-Semibold"), url("SF-Pro-Text-Semibold.otf");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "sfPro";
    src: local("SF-Pro-Text-Bold"), local("SF-Pro-Text-Bold"), url("SF-Pro-Text-Bold.otf");
    font-weight: 700;
    font-style: normal;
  }
